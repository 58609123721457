import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import './banner.scss'

const Banner = ({ imageUrl, plan = {} }) => {
  const { title } = plan

  return (
    <div className="banner">
      <div className="banner__inner-banner">
        <Img
          fluid={{
            aspectRatio: 1.5,
            sizes: '(max-width: 100%) 100vw, 100vw',
            src: `${imageUrl}`,
            srcSet: `${imageUrl} 1x`
          }}
          alt={title}
        />

        <div className="vertical-align">
          <h2 className="color-white">{title}</h2>
        </div>
      </div>
    </div>
  )
}

Banner.propTypes = {
  imageUrl: PropTypes.string,
  plan: PropTypes.shape({})
}

export default Banner
