import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import NumberFormat from 'react-number-format'
import { Link } from 'react-router-dom'

import Rating from '../rating'

import './grid.scss'

const Grid = ({ list }) => {
  return (
    <div className="columns is-vcentered is-multiline">
      {list.map(({ title, slug, price, isTop, rating, gallery }, i) => {
        const imageSrc = gallery && gallery[0] ? gallery[0].url : ''

        return (
          <div className="column is-4" key={i}>
            <div className="hotel-item style-4">
              <div className="radius-top">
                <Img
                  fluid={{
                    aspectRatio: 1.5,
                    sizes: '(max-width: 100%) 100vw, 100vh',
                    src: `${imageSrc}`,
                    srcSet: `${imageSrc} 1x`
                  }}
                  alt={title}
                />
                {isTop && (
                  <div className="price price-s-2 green tt">Oferta!</div>
                )}
              </div>

              <div className="title bg-white">
                <h4 className="grid__title">
                  <b>{title}</b>
                </h4>

                <div className="rate-wrap">
                  <div className="rate">
                    <Rating value={rating} />
                  </div>
                  <i>Calificación</i>
                </div>

                <div className="grid__price">
                  Desde{' '}
                  <span className="color-red-3">
                    <NumberFormat
                      value={price}
                      displayType={'text'}
                      thousandSeparator={true}
                      decimalSeparator={'.'}
                      prefix={'$'}
                    />
                  </span>{' '}
                  / Persona
                </div>

                <div className="grid__view-more">
                  <Link
                    to={`/plans/${slug}`}
                    className="c-button bg-red-3 hv-red-3-o delay-2 b-40"
                  >
                    Conocer Más
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

Grid.propTypes = {
  list: PropTypes.array
}

export default Grid
