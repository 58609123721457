import React from 'react'

const View = () => (
  <div
    style={{
      width: '100%',
      height: '500px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <p>
      <a href="/">Click here to go back home</a>{' '}
    </p>
  </div>
)

export default View
