import React from 'react'
import PropTypes from 'prop-types'

import './section.scss'

const Section = ({
  children,
  id,
  darker,
  title,
  subTitle,
  titleTheme,
  bgImage
}) => {
  return (
    <section
      className={`section padd-90 ${darker ? 'bg-grey-2' : ''} ${
        bgImage ? 'background-block' : ''
      }`}
      style={{
        backgroundImage: `${bgImage ? `url(${bgImage}` : ''})`,
        display: 'block'
      }}
      id={id}
    >
      <div className="main-wraper">
        <div className="container">
          {(title || subTitle) && (
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-sm-offset-2">
                <div className={`second-title ${titleTheme ? titleTheme : ''}`}>
                  {title && (
                    <h4 className="subtitle color-blue-2 underline">{title}</h4>
                  )}

                  {subTitle && (
                    <h2 className="color-dark-2-light">{subTitle}</h2>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="row">{children}</div>
        </div>
      </div>
    </section>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  darker: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  titleTheme: PropTypes.string,
  bgImage: PropTypes.string
}

Section.defaultProps = {
  id: null,
  darker: false,
  title: null,
  subTitle: null,
  titleTheme: null
}

export default Section
