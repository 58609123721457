export default (
  state = { testimonials: [], loading: false },
  { type, testimonials = [], error = '' }
) => {
  switch (type) {
    case 'LOAD_TESTIMONIALS_LOADING':
      return {
        ...state,
        error,
        loading: true
      }

    case 'LOAD_TESTIMONIALS_SUCCESS':
      return {
        ...state,
        testimonials,
        error,
        loading: false
      }

    case 'LOAD_TESTIMONIALS_ERROR':
      return {
        ...state,
        error,
        loading: false
      }

    default:
      return state
  }
}
