import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Scroll from 'react-scroll'
import { Link as RouteLink } from 'react-router-dom'

const Link = Scroll.Link

const Menu = ({ menuItems, isOpen }) => {
  const [activeItem, setActiveItem] = useState(null)
  const [subMenu1, setSubMenu1] = useState(false)

  const handleSetActive = (to) => {
    setActiveItem(to)
  }

  return (
    <nav className={`menu ${isOpen ? 'slide-menu' : ''}`}>
      <ul>
        <li className={`type-1 ${activeItem === '' ? 'active' : ''}`}>
          <RouteLink to="/">Home</RouteLink>
        </li>
        <li className={`type-1 ${activeItem === 'Nacionales' ? 'active' : ''}`}>
          <a href="#" onClick={() => setSubMenu1(!subMenu1)}>
            Destinos<span className="fa fa-angle-down"></span>
          </a>
          <ul
            className="dropmenu"
            style={{ display: subMenu1 ? 'block' : 'none' }}
          >
            <li>
              <Link
                activeClass="active"
                to="nacionales"
                smooth={true}
                duration={500}
                onSetActive={handleSetActive}
              >
                Nacionales
              </Link>
            </li>
            <li className="type-1">
              <Link
                activeClass="active"
                to="internacionales"
                smooth={true}
                duration={500}
                onSetActive={handleSetActive}
              >
                Internacionales
              </Link>
            </li>
          </ul>
        </li>
        <li className={`type-1 ${activeItem === '' ? 'active' : ''}`}>
          <a
            target="_blank"
            href="https://viajesyaventuras.com.co/documentacion/"
          >
            Documentos y Visas
          </a>
        </li>
        <li className="type-1">
          <Link
            activeClass="active"
            to="testimonios"
            smooth={true}
            duration={500}
            onSetActive={handleSetActive}
          >
            Testimonios
          </Link>
        </li>
        <li className="type-1">
          <Link
            activeClass="active"
            to="contacto"
            smooth={true}
            duration={500}
            onSetActive={handleSetActive}
          >
            Contacto
          </Link>
        </li>
      </ul>
    </nav>
  )
}

Menu.propTypes = {
  menuItems: PropTypes.array
}

Menu.defaultProps = {
  menuItems: []
}

export default Menu
