export default (
  state = { slides: [], loading: false },
  { type, slides = [], error = '' }
) => {
  switch (type) {
    case 'LOAD_SLIDES_LOADING':
      return {
        ...state,
        error,
        loading: true
      }

    case 'LOAD_SLIDES_SUCCESS':
      return {
        ...state,
        slides,
        error,
        loading: false
      }

    case 'LOAD_SLIDES_ERROR':
      return {
        ...state,
        error,
        loading: false
      }

    default:
      return state
  }
}
