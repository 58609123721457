import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import Home from './views/Home'
import Plan from './views/Plan'
import Visas from './views/Visas'
import NoMatch from './views/NoMatch'
import Layout from './layout/Layout'

import config from '../config'
import store from './store'

import './index.scss'

const {
  siteMetadata: { title }
} = config

const withLayout = (View) => Layout(View)

const HomeView = withLayout(Home)

const App = () => {
  return (
    <>
      <Switch>
        <Route exact path="/">
          <HomeView title={title} viewTitle={'Home'} />
        </Route>
        <Route path="/plans/:slug">
          <Plan />
        </Route>
        <Route path="/documentacion-visas">
          <Visas />
        </Route>
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </>
  )
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store()}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
)
