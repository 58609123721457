import React, { lazy, memo, Suspense, useEffect, useState } from 'react'

import config from '../../../config'
import { getSlides, getPlans, getTestimonials, getEmployees } from '../../api'
import { cache } from '../../utils'

import Slider from '../../components/slider'
import Section from '../../components/section/section'
import PlansGrid from '../../components/grid'
import withPlansGrid from './with-plans-grid'

import './Home.scss'

const { siteMetadata, S3_URL } = config

const LazyPromos = lazy(() => import('../../components/promos'))
const LazyTeamlist = lazy(() => import('../../components/teamlist'))
const LazyTestimonials = lazy(() => import('../../components/testimonials'))
const LazyContact = lazy(() => import('../../components/contact'))
const LazyPanel = lazy(() => import('../../components/fpanel'))

const LocalPlansGrid = withPlansGrid(PlansGrid)

const fetchData = (fn) => {
  async function next(fn) {
    const slides = await getSlides()
    const testimonials = await getTestimonials()
    const employees = await getEmployees()
    const promos = await getPlans({ filters: 'isTop=true', limit: 6 })

    return fn({ slides, testimonials, employees, promos })
  }

  return cache('home')(next)(fn)
}

const Home = () => {
  const [{ slides, testimonials, employees, promos }, setData] = useState({
    slides: [],
    promos: [],
    testimonials: [],
    employees: []
  })

  useEffect(() => {
    fetchData((res) => setData(res))
  }, [slides, testimonials, employees, promos])

  return (
    <div className="home">
      <Slider slides={slides} />
      <div className="home__content">
        <div className="home__plans">
          <>
            <Suspense fallback={<div>Loading Promos...</div>}>
              <LazyPromos promoList={promos} loading={false} />
            </Suspense>

            <a
              className="c-button bg-red-3 hv-dr-blue-2-o"
              style={{
                boxSizing: 'content-box',
                display: 'block',
                fontSize: '1.5rem',
                fontWeight: '300',
                height: '35px',
                lineHeight: '1.5',
                width: '70%',
                margin: '20px auto'
              }}
              href="https://buscador.viajesyaventura.com.co"
              target="blank"
            >
              Reservar Aquí: Vuelos, Hoteles y Planes Turísticos{'  '}
              <i className="fas fa-concierge-bell"></i>
            </a>

            <Section
              id="nacionales"
              darker={true}
              title="Destinos Nacionales"
              subTitle="Destinos en Colombia a bajo costo."
            >
              <LocalPlansGrid
                start={0}
                limit={6}
                category={'Destinos Nacionales'}
              />
            </Section>

            <Section
              id="internacionales"
              title="Destinos Internacionales"
              subTitle="Precios únicos para conocer el mundo"
            >
              <LocalPlansGrid
                start={0}
                limit={6}
                category={'Destinos Internacionales'}
              />
            </Section>
          </>
        </div>

        <div className="home__company">
          <Section
            id="testimonios"
            title="Testimonios"
            subTitle="Nuestros clientes comparten"
            titleTheme="white"
            bgImage={`${S3_URL}/e76c770cc86a4681957f790f6cba4536.jpg`}
          >
            <Suspense fallback={<div>Loading Testimonials...</div>}>
              <LazyTestimonials people={testimonials} />
            </Suspense>
          </Section>
          <Section
            title="Conoce Nuestro Equipo"
            subTitle="Juntos hacemos tus sueños realidad!"
          >
            <Suspense fallback={<div>Loading Team...</div>}>
              <LazyTeamlist teamlist={employees} />
            </Suspense>
          </Section>
          <Section
            id="contacto"
            darker={true}
            title="Ubicación Y Contacto"
            subTitle="Comunícate con nosotros"
          >
            <Suspense fallback={<div>Loading Contact Form...</div>}>
              <LazyContact {...config} />
            </Suspense>
          </Section>
        </div>
      </div>

      <Suspense fallback={<div>Loading Floating Panel...</div>}>
        <LazyPanel meta={siteMetadata} />
      </Suspense>
    </div>
  )
}

export default memo(Home)
