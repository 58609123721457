import { combineReducers } from 'redux'

import slides from './reducers/slides'
import plans from './reducers/plans'
import testimonials from './reducers/testimonials'
import employees from './reducers/employees'

export default combineReducers({
  slides,
  plans,
  testimonials,
  employees
})
