import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Link } from 'react-router-dom'

import Menu from './menu'
import './header.scss'

const Header = ({ siteTitle, isSticky }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <header className={`header hovered${isSticky ? ' sticky' : ''}`}>
      <div className="nav">
        <Link to="/" className="logo">
          <Img
            alt={siteTitle}
            fixed={{
              aspectRatio: 1.5,
              height: 42,
              width: 220,
              src: `/images/logo.png`,
              srcSet: `/images/logo.png 1x`
            }}
          />
        </Link>

        <div className="nav-menu-icon">
          <a
            href="#"
            className={`${isOpen ? 'active' : ''}`}
            onClick={(e) => {
              e.preventDefault()

              setIsOpen(!isOpen)
            }}
          >
            <i></i>
          </a>
        </div>

        <Menu isOpen={isOpen} />
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  isSticky: PropTypes.bool
}

Header.defaultProps = {
  siteTitle: ``,
  isSticky: false
}

export default Header
