import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const Bottom = ({ links }) => (
  <div className="vya-footer__bottom">
    <div className="vya-footer__bottom-copyright">
      <span>
        © Viajes y Aventura 2013-06-01 - {moment().format('YYYY-MM-DD')}
      </span>
    </div>

    <div className="vya-footer__bottom-links">
      <ul>
        {/* links here... */}
        <li>
          <a className="link-red" href="/">
            Política de Privacidad{' '}
          </a>
        </li>
      </ul>
    </div>
  </div>
)

Bottom.propTypes = {
  links: PropTypes.array
}

export default Bottom
