module.exports = {
  API_ADDRESS: 'http://ec2-18-225-26-137.us-east-2.compute.amazonaws.com:1337',
  API_BASE_ADDRESS: 'https://admin.viajesyaventura.com.co',
  API_GATEWAY_URL: 'https://5y1m2xx4x4.execute-api.us-east-2.amazonaws.com',
  S3_URL: 'https://vyaventura.s3.us-west-1.amazonaws.com',
  siteMetadata: {
    title: `Viajes y Aventura`,
    description: `Agencia de viajes en La Ceja Antioquia`,
    author: `@hrestrepop`,
    phone: '+57 (4) 4442798',
    cellphone: '+573146362914',
    contactEmail: 'gerencia@viajesyaventura.com.co'
  },
  social: {
    facebook: 'https://www.facebook.com/viajesyaventuralaceja/',
    instagram: 'https://www.instagram.com/vyaventura/?hl=es-la',
    tiktok: 'https://vm.tiktok.com/ZSH3aHfS/'
  }
}
