import React, { memo, useEffect, useState } from 'react'
// import InfiniteScroll from 'react-infinite-scroll-component'

import { cache, cacheData } from '../../utils'
import { getPlans } from '../../api'

const fetchPlans = (start, limit, category) => {
  return async function (fn) {
    const plans = await getPlans({ start, limit, filters: category })

    return fn(plans)
  }
}

const fetMorePlans = (start, limit, category) => (
  { items, ...state },
  setState
) => {
  const next = fetchPlans(start, limit, category)

  next((newItems) => {
    const updatedItems = items.concat(newItems)

    const newState = {
      ...state,
      items: updatedItems,
      hasMore: newItems.length > 0
    }

    cacheData(`plans.${category}`)(newState)

    setState(newState)
  })
}

const fetchData = (start, limit, category) => (
  { items, ...state },
  setState
) => {
  const next = fetchPlans(start, limit, category)

  const action = (memState) => {
    setState({
      ...state,
      items: memState.items
    })
  }

  return cache(`plans.${category}`)((fn) =>
    next((data) => fn({ ...state, items: data }))
  )(action)
}

const withPlansGrid = (Grid) =>
  memo(({ start, limit, category }) => {
    const [itemList, setItemList] = useState({
      items: [],
      hasMore: true,
      _start: start,
      _limit: limit,
      _category: `categories.name_contains=${category}`
    })

    const { items, hasMore, _start, _limit, _category } = itemList

    useEffect(() => {
      fetchData(_start, _limit, _category)(itemList, setItemList)
    }, [items, hasMore])

    // <InfiniteScroll
    //   dataLength={items.length}
    //   next={() =>
    //     fetMorePlans(
    //       items.length,
    //       items.length + _limit,
    //       _category
    //     )(itemList, setItemList)
    //   }
    //   hasMore={hasMore}
    //   loader={<span>Loading more...</span>}
    // >
    //   <Grid list={items} />
    // </InfiniteScroll>

    return (
      <>
        <Grid list={items} />
        <button
          className="c-button bg-red-3 hv-dr-blue-2-o"
          style={{
            display: hasMore ? 'block' : 'none',
            width: '50%',
            margin: '0 auto'
          }}
          onClick={() =>
            fetMorePlans(
              items.length,
              items.length + _limit,
              _category
            )(itemList, setItemList)
          }
        >
          Ver más
        </button>
      </>
    )
  })

export default withPlansGrid
