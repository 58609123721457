import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import SEO from './Seo'
import Header from './Header'
import Footer from './Footer'

const Layout = (View) => ({ viewTitle, title, ...attrs }) => {
  const [isSticky, setSticky] = useState(false)
  const ref = useRef(null)

  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top < 0)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])

  return (
    <>
      <SEO appName={title} title={viewTitle} lang={'es'} />
      <Header siteTitle={title} isSticky={isSticky} />
      <main ref={ref}>
        <View {...attrs} />
      </main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  View: PropTypes.node
}

export default Layout
