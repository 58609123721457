import React from 'react'

import './spinner.scss'

const Spinner = ({ text = '' }) => {
  return (
    <div className="spinner-wrapper">
      <div className="spinner-wrapper__text">{text.length > 0 && text}</div>
      <div className="spinner-wrapper__loader">
        <div className="spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  )
}

export default Spinner
