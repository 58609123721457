import React from 'react'

import Bottom from './bottom'
import './footer.scss'

const Footer = () => (
  <footer className="vya-footer">
    <Bottom links={[]} />
  </footer>
)

export default Footer
