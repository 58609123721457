import React from 'react'
import PropTypes from 'prop-types'

import './rating.scss'

const Rating = ({ value }) => {
  const porcentage = `${((parseFloat(value) || 1) * 100) / 5}%`

  return (
    <div className="rating">
      <div className="rating__back-stars">
        <span className="fa fa-star" aria-hidden="true"></span>
        <span className="fa fa-star" aria-hidden="true"></span>
        <span className="fa fa-star" aria-hidden="true"></span>
        <span className="fa fa-star" aria-hidden="true"></span>
        <span className="fa fa-star" aria-hidden="true"></span>

        <div className="rating__front-stars" style={{ width: porcentage }}>
          <span className="fa fa-star color-yellow" aria-hidden="true"></span>
          <span className="fa fa-star color-yellow" aria-hidden="true"></span>
          <span className="fa fa-star color-yellow" aria-hidden="true"></span>
          <span className="fa fa-star color-yellow" aria-hidden="true"></span>
          <span className="fa fa-star color-yellow" aria-hidden="true"></span>
        </div>
      </div>
    </div>
  )
}

Rating.propTypes = {
  value: PropTypes.number
}

export default Rating
