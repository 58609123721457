import { curry, apply } from 'ramda'

/**
 * Debounce
 *
 * @param {Boolean} immediate If true run `fn` at the start of the timeout
 * @param  timeMs {Number} Debounce timeout
 * @param  fn {Function} Function to debounce
 *
 * @return {Number} timeout
 * @example
 *
 *		const say = (x) => console.log(x)
 *		const debouncedSay = debounce(false, 1000, say)();
 *
 *		debouncedSay("1")
 *		debouncedSay("2")
 *		debouncedSay("3")
 *
 */

const debounce = curry((immediate, timeMs, fn) => () => {
  let timeout

  return (...args) => {
    const later = () => {
      timeout = null

      if (!immediate) {
        apply(fn, args)
      }
    }

    const callNow = immediate && !timeout

    clearTimeout(timeout)

    timeout = setTimeout(later, timeMs)

    if (callNow) {
      apply(fn, args)
    }

    return timeout
  }
})

const cacheData = (key) => (data) => {
  window['__data'][key] = data

  return data
}

const cache = (key) => (next) => (action) => {
  if (!window['__data']) {
    window['__data'] = {}
  }

  const data =
    window['__data'] && window['__data'][key] ? window['__data'][key] : null

  if (!data) {
    return next((data) => {
      cacheData(key)(data)

      return action(data)
    })
  }

  return action(data)
}

export { debounce, cache, cacheData }
