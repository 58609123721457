import React from 'react'
import PropTypes from 'prop-types'
import Carousel from 'nuka-carousel'
import Img from 'gatsby-image'

import Spinner from '../spinner'
import './slider.scss'

const ArrowButton = ({ direction, className, pos }) => {
  return (
    <button
      type="button"
      className={`slider__control-btn ${pos}`}
      onClick={direction}
    >
      <span className={`slider__${className}`}></span>
    </button>
  )
}

const Slider = ({ slides = [], loading = false }) => {
  return (
    <div className="slider">
      {loading || (slides.length <= 0 && <Spinner />)}

      {!loading && slides.length > 0 && (
        <Carousel
          pauseOnHover={true}
          autoplay={true}
          autoplayInterval={4000}
          easing={'easeCircle'}
          renderCenterLeftControls={({ previousSlide }) =>
            ArrowButton({
              direction: previousSlide,
              className: 'control-btn-prev',
              pos: 'left'
            })
          }
          renderCenterRightControls={({ nextSlide }) =>
            ArrowButton({
              direction: nextSlide,
              className: 'control-btn-next',
              pos: 'right'
            })
          }
          renderBottomCenterControls={() => null}
        >
          {slides.map(({ image, heading, description, url }, i) => (
            <div className="slider__slide" key={i}>
              <Img
                className="slider__img"
                fluid={{
                  aspectRatio: 1.5,
                  sizes: '(max-width: 100%) 100vh, 100vh',
                  src: `${image.url}`,
                  srcSet: `${image.url} 1x`
                }}
                alt={heading}
              />
              <div className="slider__caption">
                <div className="main-title">
                  <h1 className="color-white-op delay-2">{description}</h1>
                  <h3 className="color-white delay-1">{heading}</h3>
                  <div className="slider__view-more">
                    <a
                      href={url}
                      className="c-button small fr border-white"
                    >
                      Conocer Más
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      )}
    </div>
  )
}

Slider.propTypes = {
  items: PropTypes.array
}

export default Slider
