import React from 'react'
import PropTypes from 'prop-types'
import Carousel from 'nuka-carousel'

const CarouselHOC = ({ children, leftArrow, rightArrow }) => {
  return (
    <div style={{ position: 'relative' }}>
      <Carousel
        pauseOnHover={true}
        autoplay={true}
        autoplayInterval={4000}
        easing={'easeCircle'}
        renderCenterLeftControls={() => leftArrow}
        renderCenterRightControls={() => rightArrow}
        renderBottomCenterControls={() => null}
      >
        {children}
      </Carousel>
    </div>
  )
}

CarouselHOC.propTypes = {
  children: PropTypes.node.isRequired,
  numberOfCards: PropTypes.number,
  gutter: PropTypes.number
}

export default CarouselHOC
