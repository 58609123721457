import { API_GATEWAY_URL } from '../../config'

const getWrapper = (path) => {
  return fetch(`${API_GATEWAY_URL}${path}`, {
    method: 'GET'
  }).then((res) => res.json())
}

const getSlides = () => {
  return getWrapper(`/slides`)
}

const getPlans = ({ start = 0, limit = 10, filters = '' }) => {
  return getWrapper(`/plans?_start=${start}&_limit=${limit}&${filters}`)
}

const getPlan = (slug) => {
  return getWrapper(`/plans?slug=${slug}`)
}

const getTestimonials = () => {
  return getWrapper(`/testimonials`)
}

const getEmployees = () => {
  return getWrapper(`/employees`)
}

export { getSlides, getPlans, getPlan, getTestimonials, getEmployees }
