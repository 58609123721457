export default (
  state = { employees: [], loading: false },
  { type, employees = [], error = '' }
) => {
  switch (type) {
    case 'LOAD_EMPLOYEES_LOADING':
      return {
        ...state,
        error,
        loading: true
      }

    case 'LOAD_EMPLOYEES_SUCCESS':
      return {
        ...state,
        employees,
        error,
        loading: false
      }

    case 'LOAD_EMPLOYEES_ERROR':
      return {
        ...state,
        error,
        loading: false
      }

    default:
      return state
  }
}
