import React, { memo, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import Img from 'gatsby-image'

import { getPlan } from '../../api'

import Layout from '../../layout/Layout'
import Banner from '../../components/inner-banner'
import Carousel from '../../components/carousel'
import Rating from '../../components/rating'
import Section from '../../components/section/section'
import Spinner from '../../components/spinner'

import config from '../../../config'

import './Plan.scss'

const { siteMetadata } = config

const View = (plan) => () => {
  const { title, rating, price, gallery, content, categories, pdf } = plan

  let history = useHistory()

  return (
    <div className="plan-detail">
      <div className="plan-detail__header">
        {gallery && !!gallery[0] && (
          <Banner imageUrl={`${gallery[0].url}`} plan={plan} />
        )}
      </div>

      <Section>
        <div className="plan-detail__columns">
          <div className="plan-detail__col-left">
            <div className="detail-header">
              <div className="plan-detail__go-back">
                <a onClick={() => history.goBack()}>
                  <span>
                    <i className="fas fa-arrow-left"></i> Volver
                  </span>
                </a>
              </div>
              <div className="detail-category color-grey-3">
                El placer de viajar con Viajes, Sueños y Aventura
              </div>
              <h2 className="detail-title color-dark-2">{title}</h2>
              <div className="detail-rate rate-wrap clearfix">
                <Rating value={rating} />
                <i>Calificación</i>
              </div>
            </div>

            <div
              className="plan-detail__gallery"
              style={{ width: '100%', maxWidth: '100%' }}
            >
              <Carousel
                leftArrow={
                  <div className="swiper-arrow-left sw-arrow">
                    <span className="fa fa-angle-left"></span>
                  </div>
                }
                rightArrow={
                  <div className="swiper-arrow-right sw-arrow">
                    <span className="fa fa-angle-right"></span>
                  </div>
                }
              >
                {gallery
                  .filter((item) => item)
                  .map(({ url }, i) => (
                    <div key={i}>
                      <Img
                        fluid={{
                          aspectRatio: 1.5,
                          sizes: '(max-width: 100%) 100vw, 100vh',
                          src: `${url}`,
                          srcSet: `${url} 1x`
                        }}
                        alt={'title'}
                      />
                    </div>
                  ))}
              </Carousel>

              <div className="plan-detail__sidenav-block">
                <h3>Información general de este paquete</h3>
                <p>{content}</p>
              </div>
            </div>
          </div>

          <div className="plan-detail__col-right">
            <div className="plan-detail__sidenav-block">
              <div className="detail-price color-dark-2">
                Precio desde{' '}
                <span className="color-dr-blue">
                  {' '}
                  <NumberFormat
                    value={price}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalSeparator={'.'}
                    prefix={'$'}
                  />
                </span>{' '}
                / persona
              </div>
            </div>

            <div className="plan-detail__sidenav-block">
              <div className="plan-detail__download-block">
                {pdf && (
                  <a
                    href={`${pdf.url ? pdf.url : '#'}`}
                    target="__blank"
                    className="c-button bg-dr-blue hv-dr-blue-o b-50"
                  >
                    <i className="fa fa-download"></i>
                    Descargar PDF
                  </a>
                )}
              </div>
            </div>

            <div className="plan-detail__sidenav-block">
              <div className="tags-buttons">
                {categories.map(({ name }, i) => (
                  <button type="button" className="tags-b" key={i}>
                    {name}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  )
}

const PlanPage = () => {
  const { slug } = useParams()

  const [openPlan, setPlan] = useState(null)

  useEffect(() => {
    getPlan(slug).then((plan) => setPlan(plan[0]))
  }, [])

  if (!openPlan) {
    return <Spinner text="Loading plan..." />
  }

  const PlanView = Layout(View(openPlan))

  const { title, gallery } = openPlan

  if (!gallery || !title) {
    return null
  }

  return <PlanView title={siteMetadata.title} viewTitle={title} />
}

export default memo(PlanPage)
